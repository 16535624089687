import React from 'react'
import { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

import { AnimationOnScroll } from 'react-animation-on-scroll'


const Gallery = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Header />
            <Banner img="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803169/CE/Services/gallery-banner_r7wl7c.webp" text="Gallery" />

            <div className="gallery-page">
                <div className="gallery-body">

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803149/CE/Gallery/9_wmrucv.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803148/CE/Gallery/8_pdjw8j.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803147/CE/Gallery/7_y2nrtr.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803147/CE/Gallery/6_rmui1d.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803146/CE/Gallery/5_imbcic.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803146/CE/Gallery/4_iymmw0.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803145/CE/Gallery/38_ljjpc0.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803144/CE/Gallery/36_fow2jd.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803144/CE/Gallery/37_w16613.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803144/CE/Gallery/34_tbntn4.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803143/CE/Gallery/35_hjmmdq.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803142/CE/Gallery/33_sgamn1.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803142/CE/Gallery/32_mkodz0.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803141/CE/Gallery/31_fwajsw.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803140/CE/Gallery/30_rhlni0.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803140/CE/Gallery/3_jgepit.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803139/CE/Gallery/29_mk1la6.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803139/CE/Gallery/28_jyawsq.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803138/CE/Gallery/27_lfhjvl.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803138/CE/Gallery/26_u9ccaa.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803137/CE/Gallery/25_hfa6ao.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803136/CE/Gallery/24_y15uzp.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803136/CE/Gallery/23_etfmqe.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803135/CE/Gallery/22_fydjrv.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803135/CE/Gallery/21_gxmgf2.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803134/CE/Gallery/20_g3rasa.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803134/CE/Gallery/2_jfoeyx.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803133/CE/Gallery/19_yhntfl.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803132/CE/Gallery/18_xqnucz.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803132/CE/Gallery/17_zmi4f7.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803131/CE/Gallery/16_bvu5ir.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803131/CE/Gallery/15_rams6l.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803130/CE/Gallery/14_zw5bef.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803129/CE/Gallery/13_rvcza9.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803129/CE/Gallery/12_wobszd.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803128/CE/Gallery/11_uz2me9.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803128/CE/Gallery/10_ximnux.webp" />
                        </AnimationOnScroll>
                    </div>

                    <div className="gallery-img">
                        <AnimationOnScroll animateIn="animate__slideInRight">
                            <img fetchpriority="high" alt="gallery" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803127/CE/Gallery/1_ltj3ps.webp" />
                        </AnimationOnScroll>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Gallery
