import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Header />
            <Banner img="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803169/CE/Services/privacy-policy-banner_o8dac1.webp" text="Privacy Policy" />
            <div className="privacy-policy">

                <h6>Effective Date: 27 Oct, 2023</h6>

                <h3>Introductions</h3>
                <p> Welcome to “Competency Enhancers” ("us," "we," or "our"). At Competency Enhancers, we value your privacy and are committed to safeguarding your personal information. This Privacy Policy is designed to provide you with a clear understanding of how we collect, use, and protect your data when you visit our website or use our services. By accessing or using our website, you consent to the practices described in this Privacy Policy.</p>

                <h3>Information We Collect</h3>
                <p>We may collect various types of information, including:</p>
                <ul>
                    <li>Personal Information: Information that can be used to identify you, such as your name, email, and contact details.</li>
                    <li>Non-Personal Information: Information that does not identify you personally but helps us improve our services.</li>
                </ul>

                <h3>How We Use Your Information</h3>
                <p>We use the information we collect for various purposes, including:</p>
                <ul>
                    <li>Providing and personalizing our services.</li>
                    <li>Communicating with you, responding to your requests, and providing customer support.</li>
                    <li>Analyzing website usage and trends to improve our content and services.</li>
                    <li>Ensuring the security and integrity of our website and services.</li>
                    <li>Complying with legal obligations.</li>
                </ul>

                <h3>Third-Party Disclosure</h3>
                <p>  We do not sell, trade, or transfer your personal information to outside parties. However, we may share information with trusted third parties who assist us in operating our website, conducting our business, or servicing you.</p>

                <h3>Cookies and Tracking Technologies</h3>
                <p> We may use cookies and similar tracking technologies to enhance your browsing experience. You can choose to disable cookies through your browser settings, but this may affect your ability to access certain features of our website.</p>

                <h3>Data Security</h3>
                <p>We implement a variety of security measures to maintain the safety of your personal information. While we take all reasonable precautions, no method of data transmission or storage is completely secure, and we cannot guarantee the security of your data.</p>

                <h3>Your Choices</h3>
                <p> You have the right to review, update, or delete your personal information. You may also opt out of receiving communication from us at any time.</p>


                <h3>Policy Updates</h3>
                <p>This Privacy Policy may be updated to reflect changes to our information practices. We will notify you of any significant changes through our website.</p>

                <h3>Contact Us</h3>
                <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at trainings@competencyenhancers.com</p>
                <p> By using our website, you consent to this Privacy Policy. We encourage you to review this policy periodically for any updates or changes.</p>
                <p>Thank you for trusting Competency Enhancers with your personal information. Your privacy is important to us.</p>

            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
