import React, { useEffect } from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import Banner from '../../../Utilities/Banner';

const TrainTheTrainer = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='corp'>
            <Header />
            <Banner img="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803155/CE/Services/banner_for_train_the_trainer_ri8hqm.webp" text="Train The Trainer / Coach Program" display="flex" />

            <div className="corporate train-the-trainer">
                <div className="corporate-body">
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803187/CE/Services/Training/Train%20The%20Trainer/Soft_Skills_Trainer_shsgaf_i7cozb.webp" alt="Soft Skills Trainer" />
                        <h3>Soft Skills Trainer </h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803186/CE/Services/Training/Train%20The%20Trainer/Life_Skills_Trainer_juv6kz_ilw2q2.webp" alt="Life Skills Trainer" />
                        <h3>Life Skills Trainer </h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803185/CE/Services/Training/Train%20The%20Trainer/Happiness_Coach_fzzsvb_orngoq.webp" alt="Happiness Coach" />
                        <h3>Happiness Coach</h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803186/CE/Services/Training/Train%20The%20Trainer/Parenting_Coach_hz9efj_mykx0s.webp" alt="Parenting Coach" />
                        <h3>Parenting Coach</h3>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default TrainTheTrainer
