import React, { useEffect } from 'react'
import AdminDashboardNav from './AdminDashboardNav'
import fb from "../Firebase/firebase";
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    let navigate = useNavigate();
    let id;

    useEffect(() => {

        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                id = user.uid;
                navigate('/admin');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });
    }, [])

    return (
        <div>
            <div className='dashboard'>
                <AdminDashboardNav />
                <div className='dashboard-app' style={{ padding: '0' }}>
                    <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698388530/Competency%20Enhancers/Admin%20Panel/wise_finserv_private_wealth_dq8bvn.png" alt="banner" style={{ width: '100%' }} />
                </div>
            </div>
        </div >
    )
}

export default Admin
