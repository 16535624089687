import React from 'react'
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Form from './Form';


const Slider = () => {

    return (
        <div className='slider'>
            <Carousel autoPlay infiniteLoop interval={3000}>
                <div className='carousel-content'>
                    <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803188/CE/Slider/1_hufjdi.webp" alt='Measure, Enhance and Maintain your employee effectiveness.'/>
                    <div className="carousel-body">
                        <div className="carousel-text">
                            <h3>Measure, Enhance and Maintain your employee effectiveness.</h3>
                            <span>Corporate and Individual Training Program</span>
                            <p>We enable you to create a work environment where employees are not only happily working but they are having a purposeful engagement with a growth mindset.</p>
                            <Link to={"/services/training"}>discover more</Link>
                        </div>
                    </div>
                </div>

                <div className='carousel-content'>
                    <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803189/CE/Slider/3_lnbjj4.webp" alt='Interact, develop and Attain your goals'/>
                    <div className="carousel-body">
                        <div className="carousel-text">
                            <h3>Interact, develop and Attain your goals</h3>
                            <span>Coaching Program</span>
                            <p className='p2'>We accompany you by continuous interaction, guidance, development and improvement in your professional and personal lifestyle to take you through your desired goals</p>
                            <Link to={"/services/coaching"}>discover more</Link>
                        </div>
                    </div>
                </div>

                <div className='carousel-content'>
                    <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803189/CE/Slider/2_a1d5zb.webp" alt='Talk, Walk and Get through your pain, doubts and Troubles'/>
                    <div className="carousel-body">
                        <div className="carousel-text">
                            <h3>Talk, Walk and Get through your pain, doubts and Troubles</h3>
                            <span>Counselling Program</span>
                            <p className='p3'>We understand that one need empathy, not sympathy to come out of their pain and troubles. We also help an individual to self explore the right way by questioning and restructuring their answers.</p>
                            <Link to={"/services/counselling"}>discover more</Link>
                        </div>
                    </div>
                </div>
            </Carousel>
            <Form />
        </div>
    )
}

export default Slider
