import React, { useEffect } from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import Banner from '../../../Utilities/Banner';

const Corporate = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='corp'>
            <Header />
            <Banner img="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803154/CE/Services/Banner_for_corporates_qvqt7d.webp" text="Training For Corporate" display="flex" />
            <div className="corporate">
                <div className="corporate-body">
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803176/CE/Services/Training/Corporate/Soft_Skills_for_Professional_Success_nxzlxw_oqy2df.webp" alt="Soft Skills for Professional Success" />
                        <h3>Soft Skills for Professional Success</h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803172/CE/Services/Training/Corporate/Creating_a_Productive_Workplace_For_Employers_rxj1t4_dxwnpn.webp" alt="Creating a Productive Workplace (For Employers)" />
                        <h3>Creating a Productive Workplace (For Employers)</h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803172/CE/Services/Training/Corporate/Creating_a_Positive_Learning_Environment_For_Employers_kkltt6_j95sox.webp" alt="Creating a Positive Learning Environment (For Employers)" />
                        <h3>Creating a Positive Learning Environment (For Employers)</h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803174/CE/Services/Training/Corporate/Impactful_Positive_Communication_lcy0ue_rbeavl.webp" />
                        <h3>Impactful Positive Communication </h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803171/CE/Services/Training/Corporate/Being_Productive_at_Workplace_For_Employees_v12ave_tdsxgh.webp" alt="Being Productive at Workplace (For Employees)" />
                        <h3>Being Productive at Workplace (For Employees) </h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803171/CE/Services/Training/Corporate/Conquering_Stress_and_Anxiety_for_Professionals_ky9mmz_szbkoo.webp" alt="Conquering Stress and Anxiety for Professionals" />
                        <h3>Conquering Stress and Anxiety for Professionals</h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803173/CE/Services/Training/Corporate/Establishing_self_as_a_brand_within_organization_uwc4kv_nsuiht.webp" alt='Establishing self as a brand within organization' />
                        <h3>Establishing self as a brand within organization </h3>
                    </div>

                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803175/CE/Services/Training/Corporate/Impactful_Skills_of_Sales_and_Negotiation_tjzho6_dnholo.webp" alt='Impactful Skills of Sales and Negotiation' />
                        <h3>Impactful Skills of Sales and Negotiation </h3>
                    </div>

                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803175/CE/Services/Training/Corporate/NLP_Techniques_for_Professionals_zix65w_hyiwfe.webp" alt="NLP Techniques for Professionals" />
                        <h3>NLP Techniques for Professionals</h3>
                    </div>

                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803176/CE/Services/Training/Corporate/Positive_Psychology_g5tx2f_uc0rk7.webp" alt="Positive Psychology" />
                        <h3>Positive Psychology</h3>
                    </div>

                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803173/CE/Services/Training/Corporate/Happy_Workplace_Employers_and_Employees_z5kyrv_rlx1vb.webp" alt='Happy Workplace (Employers and Employees)' />
                        <h3>Happy Workplace (Employers and Employees)</h3>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Corporate
