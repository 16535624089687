import React, { useState } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { useParams } from 'react-router-dom';
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('Blogs');

const BlogDetails = () => {

    const { id } = useParams();
    const [blogs, Setblogs] = useState([]);
    Blogslist.doc(id).get().then((snapshot) => {
        const data = snapshot.data()
        Setblogs(data);
    });

    return (
        <div>
            <Header />
            <div className="blog-details">
                <div className="blog-details-img">
                    <img fetchpriority="high" src={blogs.Image} alt={blogs.Title} />
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" /></svg>
                        {blogs.Date}
                    </span>
                </div>
                <div className="blog-details-text">
                    <h1>{blogs.Title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: blogs.Body }}></div>
                </div>
            </div >
            <Footer />
        </div >
    )
}

export default BlogDetails
