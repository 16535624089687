import React from 'react'
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const CF = DB.collection('Get_In_Touch_Form');

const Form = () => {

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Message: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Message, Service } = userData;
        if (Name && Email && Mobile && Message && Service) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    alert("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Message: Message,
                    Service: Service
                }).then((docRef) => {
                    alert('Message Sent Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });

                emailjs.sendForm('service_19wshwo', 'template_mpaugv7', form.current, 'x1SeJi2Eu9b8s0jqB')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Message: "",
                    Service: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                alert("Error adding document")
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    const [selectContent, setSelectContent] = useState("Select Service");
    const [showTraining, setshowTraining] = useState(false);
    const [showCoaching, setshowCoaching] = useState(false);

    const select = (e) => {
        let afds = e.target.getAttribute("value");
        // alert(afds);
        setSelectContent(afds);
    }

    const showt = event => {
        setshowTraining(true);
        // setIsShownall(false);
    };

    const showc = event => {
        setshowCoaching(current => !current);
        // setIsShownall(false);
    };


    return (
        <div>
            <div className="form">
                <h3>Get In Touch</h3>
                <form method="post" ref={form}>

                    <input type="text"
                        placeholder='Your Name'
                        name='Name'
                        value={userData.Name}
                        onChange={postUserData}
                        required />

                    <input type="email"
                        placeholder='Your Email'
                        name='Email'
                        value={userData.Email}
                        onChange={postUserData}
                        required />

                    <input type="text"
                        placeholder='Your Phone'
                        name='Mobile'
                        value={userData.Mobile}
                        onChange={postUserData}
                        required />

                    <div className="drop">
                        <button className='drop-btn' value={selectContent}>
                            {selectContent}
                        </button>

                        <div className="drop-1">
                            <div className="drop-content"  onClick={showt}>
                                <h6> Training</h6>
                                <svg id="Layer_2_00000142871856314831237010000014254758634509379238_" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="_180" d="m55.5 178 185.7 185.7c8.2 8.2 21.5 8.2 29.7 0l185.7-185.7c13.2-13.2 3.9-35.9-14.9-35.9h-371.4c-18.7 0-28.1 22.7-14.8 35.9z" /></g></svg>

                                {showTraining && (
                                    <div className="d-m">
                                        <p value="Training - For Corporate" onClick={select}>For Corporate</p>
                                        <p value="Training - For Individuals" onClick={select}>For Individuals</p>
                                        <p value="Training - Train The Trainer / Coach Program" onClick={select}>Train The Trainer / Coach Program</p>
                                    </div>
                                )}
                            </div>
                            <div className="drop-content" onClick={showc}>
                                <h6 >Coaching</h6>
                                <svg id="Layer_2_00000142871856314831237010000014254758634509379238_" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="_180" d="m55.5 178 185.7 185.7c8.2 8.2 21.5 8.2 29.7 0l185.7-185.7c13.2-13.2 3.9-35.9-14.9-35.9h-371.4c-18.7 0-28.1 22.7-14.8 35.9z" /></g></svg>
                                {showCoaching && (
                                    <div className="d-m">
                                        <p value="Coaching - Balancing Life" onClick={select}>Balancing Life</p>
                                        <p value="Coaching - Achieving Personal and Professional Goals" onClick={select}>Achieving Personal and Professional Goals</p>
                                        <p value="Coaching - Parenting" onClick={select}>Parenting</p>
                                        <p value="Coaching - Relationships" onClick={select}>Relationships</p>
                                        <p value="Coaching - Positive Psychology" onClick={select}>Positive Psychology</p>
                                    </div>
                                )}
                            </div>
                            <div className="drop-content">
                                <h6 value="Couneslling" onClick={select}>Counselling</h6>
                            </div>

                        </div>
                    </div>

                    <textarea name="Message"
                        placeholder='Your Message'
                        value={userData.Message}
                        onChange={postUserData}
                        required>
                    </textarea>

                    <button onClick={SubmitData}>Send Message</button>
                </form>
            </div>
        </div>
    )
}

export default Form
