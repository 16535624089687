import React, { useEffect } from 'react'
import Header from '../../../Utilities/Header'
import Footer from '../../../Utilities/Footer'
import Banner from '../../../Utilities/Banner';

const Individual = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='corp'>
            <Header />
            <Banner img="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803154/CE/Services/banner_for_individuals_jarpta.webp" text="Training For Individuals" display="flex" />

            <div className="corporate">
                <div className="corporate-body">
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803183/CE/Services/Training/Individuals/ResumeCV_Building_cyaefh_bq1qxs.webp" alt="Resume/CV Building" />
                        <h3>Resume/CV Building</h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803183/CE/Services/Training/Individuals/Preparing_Self_for_Interview_lxtjuw_dilurt.webp" alt="Preparing Self for Interview " />
                        <h3>Preparing Self for Interview </h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803184/CE/Services/Training/Individuals/Soft_Skills_for_successful_Professional_and_Personal_life._szoprc_yrdc72.webp" alt="Soft Skills for successful Professional and Personal life" />
                        <h3>Soft Skills for successful Professional and Personal life. </h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803181/CE/Services/Training/Individuals/Conquering_Fear_Stress_and_Anxiety_xoy7ux_yt7da3.webp" />
                        <h3>Conquering Fear, Stress and Anxiety</h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803182/CE/Services/Training/Individuals/NLP_Techniques_for_Personal_Life_wngsho_k7tbhs.webp" alt="NLP Techniques for Personal Life " />
                        <h3>NLP Techniques for Personal Life </h3>
                    </div>
                    <div className="corporate-card">
                        <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803180/CE/Services/Training/Individuals/Become_an_Influencer_in_social_circle_qfjtvu_1_o68afv.webp" alt="Become an Influencer in social circle" />
                        <h3>Become an Influencer in social circle</h3>
                    </div>
                    <div className="corporate-card last">
                        <div className="corporate-card-img">
                            <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803182/CE/Services/Training/Individuals/Happiness_Practitioner_Course_pn1kmo_jwnnvf.webp" alt="Happiness Practitioner Course" />
                            <h3>Happiness Practitioner Course </h3>
                        </div>

                        <div className="corporate-card-text">
                            <ul>
                                <li>Happiness Coach </li>
                                <li>Happiness Master Practitioner Level</li>
                                <li>Happiness Practitioner Level</li>
                                <li>Basic Level</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Individual
