import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    const [displays, SetDisplays] = useState("none");

    const show = () => {
        SetDisplays('block')
    }
    const hide = () => {
        SetDisplays('none')
    }
    return (
        <>
            <div className='header'>
                <Link className='logo' to={"/"}><img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1703924108/CE/Logo/ce_logo_cpnuqj.png" alt="logo" /></Link>
                <div className='header-links'>
                    <Link to={"/"}>Home</Link>
                    <Link to={"/about"}>About Us</Link>
                    <div className='serv'>Services
                        <div id='services'>
                            <Link to={"/services/training"} id='tra'>
                                Training&nbsp;<svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m383.9 300.8c25.139-25.14 28.977-59.53-.086-89.679l-197.731-197.734a45.893 45.893 0 0 0 -64.9 64.9l177.706 177.713-177.709 177.71a45.893 45.893 0 1 0 64.9 64.9z" fill-rule="evenodd" /></svg>
                                <div id='training'>
                                    <Link to={"/services/training/corporate"}>For Corporate</Link>
                                    <Link to={"/services/training/individual"}>For individuals</Link>
                                    <Link to={"/services/training/train-the-trainer"}>train the trainer / coach program</Link>
                                </div>
                            </Link>
                            <Link to={"/services/coaching"}>Coaching</Link>
                            <Link to={"/services/counselling"}>Counselling</Link>
                        </div>
                    </div>
                    <Link to={"/gallery"}>Gallery</Link>
                    <Link to={"/blog"}>Blogs</Link>
                    <Link to={"/contact"}>Contact</Link>
                    <a className='book' target='_blank' rel='noreferrer' href="https://meetpro.club/Competencyenhancers.com">Book Your Slot</a>
                </div>
                <div className="header-menu" onClick={show}>
                    <svg height="512" viewBox="0 0 20 20" width="512" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m3 5c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289h12c.2652 0 .5196.10536.7071.29289.1875.18754.2929.44189.2929.70711s-.1054.51957-.2929.70711c-.1875.18753-.4419.29289-.7071.29289h-12c-.26522 0-.51957-.10536-.70711-.29289-.18753-.18754-.29289-.44189-.29289-.70711zm0 5c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289h12c.2652 0 .5196.10536.7071.29289.1875.18754.2929.44189.2929.70711 0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-12c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071zm6 5c0-.2652.10536-.5196.29289-.7071.18754-.1875.44189-.2929.70711-.2929h6c.2652 0 .5196.1054.7071.2929s.2929.4419.2929.7071-.1054.5196-.2929.7071-.4419.2929-.7071.2929h-6c-.26522 0-.51957-.1054-.70711-.2929-.18753-.1875-.29289-.4419-.29289-.7071z" fill="rgb(0,0,0)" fillRule="evenodd" /></svg>
                </div>

            </div>
            <div className="header-mobile" data-mdb-toggle="animation" data-mdb-animation-reset="true" data-mdb-animation="slide-in-right" style={{ display: displays }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" onClick={hide} /></svg>
                <div className="header-mobile-links">
                    <Link to={"/"}>Home</Link>
                    <Link to={"/about"}>About Us</Link>
                    <div href="" className='serv'>Services
                        <ul id='services'>
                            <Link to={"/services/training"}>Training</Link>
                            <Link to={"/services/coaching"}>Coaching</Link>
                            <Link to={"/services/counselling"}>Counselling</Link>
                        </ul>
                    </div>
                    <Link to={"/gallery"}>Gallery</Link>
                    <Link to={"/blog"}>Blogs</Link>
                    <Link to={"/contact"}>Contact</Link>
                    <a className='book' target='_blank' rel='noreferrer' href="https://meetpro.club/Competencyenhancers.com">Book Your Slot</a>
                </div>
            </div>
            <a className='whatsapp' href="https://wa.me/919205085307">
                <h6>Chat Now</h6>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
            </a>
        </>
    )
}

export default Header
