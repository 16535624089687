import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { useEffect } from 'react'
import Banner from '../Utilities/Banner'
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const CF = DB.collection('Contact_Form');

const Contact = () => {
  
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const form = useRef();
  const [userData, setUserData] = useState({
    Name: "",
    Email: "",
    Mobile: "",
    Message: ""

  });
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  }

  //connect with firebase
  const SubmitData = async (e) => {
    e.preventDefault();
    const { Name, Email, Mobile, Message } = userData;
    if (Name && Email && Mobile && Message) {


      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(userData.Email)) {
        alert('Please enter a valid email address');
        return
      }

      if (typeof Mobile !== "undefined") {

        var pattern = new RegExp(/^[0-9\b]+$/);

        if (!pattern.test(userData.Mobile)) {
          alert("Please enter only number.");
          return

        } else if (userData.Mobile.length !== 10) {
          alert("Please enter 10 digit Mobile number.");
          return
        }
      }

      try {

        CF.add({
          Name: Name,
          Email: Email,
          Mobile: Mobile,
          Message: Message
        }).then((docRef) => {
          alert('Message Sent Successfully');
        }).catch((error) => {
          console.error("error:", error);
        });

        emailjs.sendForm('service_19wshwo', 'template_atpd3qk', form.current, 'x1SeJi2Eu9b8s0jqB')
          .then((result) => {
            console.log(result.text);

          }, (error) => {
            console.log(error.text);
          });

        setUserData({
          Name: "",
          Email: "",
          Mobile: "",
          Message: ""
        })

        // console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
        alert("Error adding document")
      }
    }
    else {
      alert('Please fill the data');
    }
  };


  return (
    <div>
      <Header />
      <Banner img="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803162/CE/Services/contact-banner_qoptez.webp" text="Contact Us" />

      <div className="contact-page">
        <div className="contact-page-body">
          <div className="contact-p-left">

            <div className="contact-p-left-content">
              <div className='svg'>
                <svg height="511pt" viewBox="0 1 511.99995 511" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m128.378906 272.066406h-22.960937c-6.023438 0-11.460938 3.585938-13.84375 9.109375l-63.5625 148.003907 150.953125-77.097657c-18.070313-25.003906-35.949219-52.582031-50.585938-80.015625zm0 0" /><path d="m420.421875 281.175781c-2.378906-5.523437-7.820313-9.109375-13.839844-9.109375h-22.960937c-27.640625 51.796875-67.910156 105.324219-93.527344 134.773438-18.015625 20.664062-50.195312 20.640625-68.183594 0-1.839844-2.113282-11.445312-13.234375-24.675781-30.335938l-27.214844 13.90625 102.945313 102.746094 195.0625-101.125zm0 0" /><path d="m9.441406 472.433594-8.210937 19.121094c-4.25 9.894531 3.03125 20.945312 13.839843 20.945312h221.050782c1.382812-1.027344 1.398437-.964844 8.820312-4.8125l-103.097656-102.882812zm0 0" /><path d="m510.769531 491.554688-30.84375-71.820313-178.9375 92.765625h195.941407c10.785156 0 18.097656-11.03125 13.839843-20.945312zm0 0" /><path d="m271.058594 135.824219c0-8.289063-6.757813-15.027344-15.058594-15.027344-8.304688 0-15.058594 6.738281-15.058594 15.027344 0 8.285156 6.753906 15.027343 15.058594 15.027343 8.300781 0 15.058594-6.742187 15.058594-15.027343zm0 0" /><path d="m267.363281 386.117188c5.070313-5.820313 124.160157-143.578126 124.160157-238.273438 0-195.265625-271.046876-197.648438-271.046876 0 0 94.695312 119.089844 232.453125 124.160157 238.273438 6.003906 6.886718 16.734375 6.878906 22.726562 0zm-56.539062-250.292969c0-24.859375 20.265625-45.082031 45.175781-45.082031 24.90625 0 45.171875 20.222656 45.171875 45.082031 0 24.855469-20.265625 45.078125-45.171875 45.078125-24.910156 0-45.175781-20.222656-45.175781-45.078125zm0 0" /></svg>
              </div>
              <div className="contact-p-left-text">
                <h6>Location</h6>
                <a target='_blank' href="https://www.google.com/maps?ll=28.571551,77.072939&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=13433932122202011891">Plot 23, Sector 18 Maruti Industrial Development Area, Gurugram, Haryana 122015</a>
              </div>

            </div>

            <div className="contact-p-left-content">
              <div className='svg'>
                <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m367.624 367.613c87.668-87.668 141.642-190.532 144.343-275.154a51.5 51.5 0 0 0 -9.454-32.265c-9.545-13.9-19.936-27.61-30.874-40.761-10.739-12.898-24.655-19.433-41.35-19.433h-.7c-17 .188-31 7.026-41.61 20.326-10.759 13.516-22.11 27.9-32.154 41.61-13.473 18.35-14.222 41.688-1.976 60.9 37.76 59.2 4.448 135.92-45.317 185.7s-126.494 83.076-185.7 45.318a52.944 52.944 0 0 0 -60.9 1.964c-13.715 10.064-28.1 21.407-41.61 32.166-13.29 10.602-20.13 24.604-20.322 41.609s6.36 31.16 19.442 42.041c13.142 10.935 26.85 21.327 40.75 30.874a51.516 51.516 0 0 0 32.265 9.456c84.626-2.714 187.489-56.673 275.167-144.351z" fillRule="evenodd" /></svg>
              </div>
              <div className="contact-p-left-text">
                <h6>Call Us</h6>
                {/* <a href="tel:918929356243">+91-8929356243</a> */}
                <a href="tel:919911365307">+91-9205085307</a>

              </div>

            </div>

            <div className="contact-p-left-content">
              <div className='svg'>
                <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m1 224.336v265.327l170.567-132.663z" /><path d="m446 155.31v81.576l50.373-39.179z" /><path d="m265.659 3.523c-5.582-4.697-13.736-4.697-19.318 0l-39.775 33.477h98.867z" /><path d="m66 155.31-50.373 42.397 50.373 39.179z" /><path d="m201.146 372-180 140h469.708l-180-140z" /><path d="m340.432 357 170.568 132.664v-265.329z" /><path d="m252.219 179.364c-12.167 0-24.752 11.83-24.752 31.626 0 20.126 12.276 33.646 23.742 33.646 12.074 0 21.975-14.127 22.687-31.889l.019-3.771c-.539-17.524-9.308-29.612-21.696-29.612z" /><path d="m310.854 342 105.146-81.781v-178.219c0-8.284-6.716-15-15-15h-290c-8.284 0-15 6.716-15 15v178.22l105.146 81.78zm-160.596-102.092c-7.163-27.235-3.561-55.947 10.144-80.846 13.699-24.89 36.023-43.302 62.86-51.845 28.313-9.015 58.376-6.359 84.647 7.478 26.275 13.838 45.471 37.114 54.05 65.541.047.154.091.31.132.466 5.702 21.36 4.014 41.524-5.474 65.379-6.413 16.124-22.136 33.354-47.031 33.354-9.032 0-17.693-3.704-24.387-10.431-1.584-1.592-3.011-3.326-4.289-5.163-8.407 6.838-18.602 10.794-29.7 10.794-14.498 0-28.594-7.038-38.673-19.31-9.718-11.832-15.069-27.577-15.069-44.336 0-16.834 5.694-32.411 16.034-43.86 10.196-11.29 24.309-17.766 38.718-17.766 7.925 0 15.456 1.889 22.223 5.448 1.312-6.953 7.407-12.206 14.726-12.206h.076c8.284.041 14.967 6.79 14.926 15.074l-.413 83.335c-.013 2.559.999 5.111 2.707 6.827.591.595 1.783 1.592 3.121 1.592 12.104 0 17.428-10.099 19.154-14.44 7.007-17.617 8.335-31.488 4.427-46.324-6.273-20.566-20.2-37.405-39.237-47.431-19.104-10.062-40.972-11.991-61.568-5.436-40.599 12.924-63.919 55.3-53.09 96.474 8.992 34.192 39.899 58.753 75.16 59.729 8.28.229 14.809 7.128 14.579 15.409-.225 8.14-6.895 14.585-14.986 14.585-.141 0-.281-.002-.423-.006-23.245-.643-46.191-8.9-64.612-23.252-19.138-14.908-32.53-35.252-38.732-58.833z" /></g></svg>
              </div>
              <div className="contact-p-left-text">
                <h6>mail us</h6>
                <a href="mailto:trainings@competencyenhancers.com">trainings@competencyenhancers.com</a>

              </div>

            </div>

            <div className="contact-p-left-content">
              <div className='svg'>
                <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m256 0c-140.61 0-256 115.39-256 256s115.39 256 256 256 256-115.39 256-256-115.39-256-256-256zm116.673 118.114c5.858-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213-15.355 5.858-21.213 0-5.858-15.355 0-21.213zm-131.673-42.114c0-8.291 6.709-15 15-15s15 6.709 15 15v30c0 8.291-6.709 15-15 15s-15-6.709-15-15zm-165 195c-8.291 0-15-6.709-15-15s6.709-15 15-15h30c8.291 0 15 6.709 15 15s-6.709 15-15 15zm63.327 122.886c-5.858 5.858-15.355 5.858-21.213 0s-5.858-15.355 0-21.213c5.858-5.859 15.355-5.859 21.213 0 5.858 5.858 5.858 15.355 0 21.213zm0-254.559c-5.858 5.858-15.355 5.858-21.213 0s-5.858-15.355 0-21.213 15.355-5.858 21.213 0 5.858 15.355 0 21.213zm131.673 296.673c0 8.291-6.709 15-15 15s-15-6.709-15-15v-30c0-8.291 6.709-15 15-15s15 6.709 15 15zm85.605-79.395c-5.859 5.859-15.352 5.859-21.211 0l-90-90c-2.812-2.812-4.394-6.621-4.394-10.605v-90c0-8.291 6.709-15 15-15s15 6.709 15 15v83.789l85.605 85.605c5.86 5.86 5.86 15.352 0 21.211zm37.281 37.281c-5.858 5.858-15.355 5.858-21.213 0s-5.858-15.355 0-21.213c5.858-5.859 15.355-5.859 21.213 0 5.857 5.858 5.857 15.355 0 21.213zm57.114-137.886c0 8.291-6.709 15-15 15h-30c-8.291 0-15-6.709-15-15s6.709-15 15-15h30c8.291 0 15 6.709 15 15z" /></g></svg>
              </div>
              <div className="contact-p-left-text">
                <h6>timings</h6>
                <a href="">Mon – Sat 9 am to 8 pm</a>
                <a href="">Sun – 10 am to 3 pm</a>

              </div>

            </div>

          </div>
          <div className="contact-p-right">
            <h5>Keep in Touch</h5>
            <form method="post" ref={form}>

              <input type="text"
                placeholder='Your Name'
                name='Name'
                value={userData.Name}
                onChange={postUserData}
                required />

              <input type="email"
                placeholder='Your Email'
                name='Email'
                value={userData.Email}
                onChange={postUserData}
                required />

              <input type="text"
                placeholder='Your Phone'
                name='Mobile'
                value={userData.Mobile}
                onChange={postUserData}
                required />
              <textarea name="Message"
                placeholder='Your Message'
                value={userData.Message}
                onChange={postUserData}
                required>
              </textarea>

              <button onClick={SubmitData}>Send Message</button>
            </form>
          </div>
        </div>
        <div className="contact-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.940296024587!2d77.07036437434263!3d28.571555486849128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b0e9aaaaaab%3A0xba6eea16d08f78f3!2sCompetency%20Enhancers!5e0!3m2!1sen!2sin!4v1695023564510!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      <Footer />
    </div >
  )
}

export default Contact
