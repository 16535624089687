import React, { useState } from 'react';
import fb from '../Firebase/firebase'
import { useNavigate } from 'react-router-dom'
import '../Sass/admin.scss'

const Login = () => {

    //fetching data from form

    const [userData, setUserData] = useState({
        Email: "",
        Password: ""

    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //fetching data from form ends

    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault();
        fb.auth().signInWithEmailAndPassword( userData.Email, userData.Password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/admin")
                console.log(user);
                alert("logged in Successfully")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                alert(errorCode, errorMessage);
            });
    }

    return (
        <div className='admin-login'>
            <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698906940/Competency%20Enhancers/Admin%20Panel/bg_z9lbao.png" alt="login" />
            <div className='admin-login-content'>
                <form onSubmit={onLogin}>
                    <h5>Admin Login</h5>
                    <hr />
                    <span>Email</span>
                    <input
                        type="email"
                        name='Email'
                        label="Email address"
                        value={userData.Email}
                        onChange={postUserData}
                        required />

                    <span>Password</span>
                    <input type="password"
                        name='Password'
                        label="Create password"
                        value={userData.Password}
                        onChange={postUserData}
                        required />
                    <input
                        className='login-button'
                        type="submit"
                        value="Login" />
                </form>
            </div>


        </div>
    )
}

export default Login
