import "./Components/Sass/style.scss"
import "./Components/Sass/media.scss"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Components/Pages/Home"
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";
import Gallery from "./Components/Pages/Gallery";
import Counselling from './Components/Pages/Services/Counselling'
import Coaching from './Components/Pages/Services/Coaching'
import Training from './Components/Pages/Services/Training'
import Blog from './Components/Pages/Blog'
import BlogDetails from './Components/Pages/BlogDetails'
import CreateBlog from './Components/AdminPanel/Blog/create'
import BlogslistView from './Components/AdminPanel/Blog/blogslist'
import Login from './Components/AdminPanel/Login'
import Admin from './Components/AdminPanel/Admin'
import ContactDB from './Components/AdminPanel/ContactDB'
import GetInTouchDB from './Components/AdminPanel/GetInTouchDB'
import ResetPassword from "./Components/AdminPanel/ResetPassword";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import Corporate from "./Components/Pages/Services/Training/Corporate";
import Individual from "./Components/Pages/Services/Training/Individual";
import TrainTheTrainer from "./Components/Pages/Services/Training/TrainTheTrainer";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* services */}
            <Route path="/services/counselling" element={<Counselling />} />
            <Route path="/services/coaching" element={<Coaching />} />
            <Route path="/services/training" element={<Training />} />

            {/* Training */}
            <Route path="/services/training/corporate" element={<Corporate />} />
            <Route path="/services/training/individual" element={<Individual />} />
            <Route path="/services/training/train-the-trainer" element={<TrainTheTrainer />} />

            {/* blog */}
            <Route path="/bloglist" element={<BlogslistView />} />
            <Route path="/create/" element={<CreateBlog />} />
            <Route path="/show/:id" element={<BlogDetails />} />
            <Route path='/blog' element={<Blog />} />


            {/* Admin */}
            <Route exact path="/admin-login" element={<Login />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/contact-form-database" element={<ContactDB />} />
            <Route exact path="/get-in-touch-form-database" element={<GetInTouchDB />} />
            <Route exact path="/admin-reset-password" element={<ResetPassword />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
