import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

  const up = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  return (
    <div>
      <footer>
        <div className="footer-body">
          <div className="footer-about">
            <h2>Competency enhancers</h2>
            <p>Competency Enhancers was incepted in the year 2013, with the aim of providing most suited training and consulting services to professionals, corporate and campuses, by deeply understanding the requirement of clients across any possible functional area.</p>


          </div>
          <div className="footer-links">
            <h4>useful Links</h4>
            <div className="footer-links-content">
              <Link onClick={up} to={"/"}><svg id="Layer_1" enableBackground="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Home</Link>
              <Link onClick={up} to={"/about"}><svg id="Layer_1" enableBackground="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;About</Link>
              <Link onClick={up} to={"/services/training"}><svg id="Layer_1" enableBackground="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Services</Link>
              <Link onClick={up} to={"/gallery"}><svg id="Layer_1" enableBackground="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Gallery</Link>
              <Link onClick={up} to={"/blog"}><svg id="Layer_1" enableBackground="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Blogs</Link>
              <Link onClick={up} to={"/contact"}><svg id="Layer_1" enableBackground="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Contact</Link>
            </div>
          </div>
          <div className="footer-contact">
            <h4>Contact info</h4>

            <div className="footer-contact-body">

              {/* <div className="footer-contact-content">
                <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Call"><path d="m52.5 42.85-2.83-2.82a5.9873 5.9873 0 0 0 -8.48 0l-1.27 1.26a1.9573 1.9573 0 0 1 -1.83.54c-2.1-.47-5.49-1.92-9.74-6.17-4.26-4.26-5.71-7.65-6.18-9.75a1.9573 1.9573 0 0 1 .54-1.83l1.27-1.27a6.01768 6.01768 0 0 0 0-8.48l-2.83-2.83a6.01143 6.01143 0 0 0 -8.48 0l-.87.87a10.64659 10.64659 0 0 0 -2.89 9.89c1.49 6.71 6.41 15.28 11.98 20.85 5.57 5.56 14.14 10.49 20.84 11.98a10.96437 10.96437 0 0 0 2.34.26 10.68538 10.68538 0 0 0 7.56-3.15l.87-.87a6.01143 6.01143 0 0 0 0-8.48z" /></g></svg>
                <a href="tel:918929356243">+91-8929356243</a>
              </div> */}
              <div className="footer-contact-content">
                <div className="svg">
                  <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Call"><path d="m52.5 42.85-2.83-2.82a5.9873 5.9873 0 0 0 -8.48 0l-1.27 1.26a1.9573 1.9573 0 0 1 -1.83.54c-2.1-.47-5.49-1.92-9.74-6.17-4.26-4.26-5.71-7.65-6.18-9.75a1.9573 1.9573 0 0 1 .54-1.83l1.27-1.27a6.01768 6.01768 0 0 0 0-8.48l-2.83-2.83a6.01143 6.01143 0 0 0 -8.48 0l-.87.87a10.64659 10.64659 0 0 0 -2.89 9.89c1.49 6.71 6.41 15.28 11.98 20.85 5.57 5.56 14.14 10.49 20.84 11.98a10.96437 10.96437 0 0 0 2.34.26 10.68538 10.68538 0 0 0 7.56-3.15l.87-.87a6.01143 6.01143 0 0 0 0-8.48z" /></g></svg>
                </div>
                <a href="tel:919205085307">+91-9205085307</a>
              </div>
              <div className="footer-contact-content">
                <div className="svg">
                  <svg viewBox="0 -77 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m490.753906 0h-469.507812l234.753906 234.453125zm0 0" /><path d="m0 21.179688v316.445312l158.425781-158.222656zm0 0" /><path d="m256 276.851562-76.347656-76.25-158.40625 158.203126h469.507812l-158.40625-158.203126zm0 0" /><path d="m353.574219 179.402344 158.425781 158.222656v-316.445312zm0 0" /></svg>
                </div>
                <a href="mailto:trainings@competencyenhancers.com" style={{ textTransform: 'lowercase' }}>trainings@competencyenhancers.com</a>
              </div>
              <div className="footer-contact-content">
                <div className="svg">
                  <svg id="Layer_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-105.6 0-191.5 85.9-191.5 191.5 0 34.4 16.2 77.8 49.4 132.7 27.6 45.6 61.8 90.9 91.9 130.8 13.9 18.4 27 35.8 37.1 50.2 3 4.3 7.9 6.8 13.1 6.8s10.1-2.5 13.1-6.8c10.1-14.4 23.2-31.8 37.1-50.2 30.1-39.9 64.2-85.2 91.9-130.8 33.2-54.9 49.4-98.3 49.4-132.7 0-105.6-85.9-191.5-191.5-191.5zm0 280c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z" /></svg>
                </div>
                <a target='_blank' rel="noreferrer" href="https://www.google.com/maps?ll=28.571551,77.072939&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=13433932122202011891">Plot 23, Sector 18 Maruti Industrial Development Area, Gurugram, Haryana 122015</a>
              </div>

            </div>

          </div>
          <div className="footer-map">
            <iframe title='location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.940296024587!2d77.07036437434266!3d28.57155548684912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b0e9aaaaaab%3A0xba6eea16d08f78f3!2sCompetency%20Enhancers!5e0!3m2!1sen!2sin!4v1694588601514!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        <div className="footer-follow">

          <a target='_blank' rel="noreferrer" href='https://www.google.com/search?q=competencyenhancers&rlz=1C1RLNS_enIN1074IN1074&oq=co&aqs=chrome.1.69i60j69i59j69i60j69i61j69i60l3j69i65.3314j0j7&sourceid=chrome&ie=UTF-8' className="svg">
            <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m420.7 450.7c-44.4 37.5-101.999 61.3-164.7 61.3-93.3 0-175.201-51.4-219.6-126.4l16.622-76.622 73.678-13.678c17.1 55.199 68.699 95.7 129.3 95.7 29.399 0 56.7-9.3 79.2-25.8l70.8 10.8z" fill="#59c36a" /><path d="m420.7 450.7-14.7-74.7-70.8-10.8c-22.5 16.5-49.801 25.8-79.2 25.8v121c62.701 0 120.3-23.8 164.7-61.3z" fill="#00a66c" /><g id="Connected_Home_1_"><g><g><g><path d="m121 256c0 13.799 2.1 26.999 5.7 39.3l-90.3 90.3c-22.5-37.8-36.4-82.201-36.4-129.6 0-47.401 13.9-91.8 36.4-129.6l72.473 12.473 17.827 77.827c-3.6 12.299-5.7 25.499-5.7 39.3z" fill="#ffda2d" /></g></g></g></g><path d="m512 256c0 77.999-36.099 147.9-91.3 194.7l-85.5-85.5c17.399-12.601 32.1-29.401 41.7-49.2h-120.9c-8.401 0-15-6.601-15-15v-90c0-8.401 6.599-15 15-15h236.8c7.2 0 13.5 5.099 14.7 12.299 3 15.601 4.5 31.8 4.5 47.701z" fill="#4086f4" /><path d="m376.901 316c-9.6 19.799-24.302 36.599-41.7 49.2l85.499 85.5c55.201-46.8 91.3-116.7 91.3-194.7 0-15.901-1.5-32.1-4.501-47.701-1.199-7.2-7.5-12.299-14.7-12.299h-236.799v120z" fill="#4175df" /><path d="m424.9 71.499c.3 4.2-1.5 8.101-4.2 11.1l-64.2 63.9c-5.099 5.4-13.499 6-19.499 1.5-23.702-17.699-51.602-26.999-81.001-26.999-60.601 0-112.2 40.499-129.3 95.7l-90.3-90.3c44.399-75 126.3-126.4 219.6-126.4 59.7 0 117.9 22 163.5 60.399 3.3 2.701 5.1 6.9 5.4 11.1z" fill="#ff641a" /><path d="m337 147.999c6 4.501 14.399 3.9 19.499-1.5l64.2-63.9c2.701-2.999 4.501-6.899 4.2-11.1s-2.1-8.399-5.4-11.1c-45.599-38.399-103.799-60.399-163.499-60.399v121c29.399 0 57.299 9.3 81 26.999z" fill="#f03800" /></g></svg>
          </a>

          <a target='_blank' rel="noreferrer" href='https://www.facebook.com/competencyenhancersindia/' className="svg">
            <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g fillRule="evenodd"><path d="m32 64h-16a16.0007 16.0007 0 0 1 -16-16v-32a16.0007 16.0007 0 0 1 16-16h32a16 16 0 0 1 16 16v32a16 16 0 0 1 -16 16h-6a5 5 0 0 0 -10 0z" fill="#3764b9" /><path d="m30 18h18a9.0006 9.0006 0 0 0 .92-17.954c-.306-.017-.609-.046-.92-.046h-32a16.0007 16.0007 0 0 0 -16 16v32a30.0007 30.0007 0 0 1 30-30" fill="#507dd2" /><path d="m48 32a16 16 0 1 0 16 16v-32a16 16 0 0 1 -16 16" fill="#1e4ba0" /><path d="m52 18a2 2 0 0 1 -2 2h-6a2 2 0 0 0 -2 2v8h7.56a2 2 0 0 1 1.9612 2.392c-.3713 1.857-.8757 4.379-1.2 6a2 2 0 0 1 -1.9612 1.608h-6.36v24h-10v-24h-6a2 2 0 0 1 -2-2v-6a2 2 0 0 1 2-2h6v-8a12 12 0 0 1 12-12h6a2 2 0 0 1 2 2z" fill="#fff" /></g></svg>
          </a>

          <a target='_blank' rel="noreferrer" href='https://in.linkedin.com/company/competency-enhancers' className="svg">
            <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g fillRule="evenodd"><path d="m48 64h-32a16.0007 16.0007 0 0 1 -16-16v-32a16.0007 16.0007 0 0 1 16-16h32a16 16 0 0 1 16 16v32a16 16 0 0 1 -16 16" fill="#2864b4" /><path d="m30 18h18a9.0006 9.0006 0 0 0 .92-17.954c-.306-.017-.609-.046-.92-.046h-32a16.0007 16.0007 0 0 0 -16 16v32a30.0007 30.0007 0 0 1 30-30" fill="#417dcd" /><path d="m48 32a16 16 0 1 0 16 16v-32a16 16 0 0 1 -16 16" fill="#0f4b9b" /></g><rect fill="#fff" height="27" rx="2" width="8" x="12" y="25" /><circle cx="16" cy="16" fill="#fff" r="5" /><path d="m34 28.64a9.2164 9.2164 0 0 1 8-4.64c5.087 0 10 3 10 11v15a1.9994 1.9994 0 0 1 -2 2h-4a1.9994 1.9994 0 0 1 -2-2v-14a5 5 0 0 0 -10 0v14a1.9994 1.9994 0 0 1 -2 2h-4a1.9994 1.9994 0 0 1 -2-2v-23a1.9994 1.9994 0 0 1 2-2h4a1.9994 1.9994 0 0 1 2 2z" fill="#fff" fillRule="evenodd" /></svg>
          </a>

          <a target='_blank' rel="noreferrer" href='https://instagram.com/competencyenhancers?igshid=NzZlODBkYWE4Ng==' className="svg">
            <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803126/CE/Footer/icons8-instagram_mfkzk1_nsfv4i.svg" alt="instagram" />
          </a>

          <a target='_blank' rel="noreferrer" href='https://www.youtube.com/@competencyenhancers' className="svg">
            <svg height="512pt" viewBox="0 -77 512.00213 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m501.453125 56.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719 0-200.328125 10.546875c-21.507813 5.902344-39.222657 23.617187-45.125 45.546875-10.542969 40.0625-10.542969 123.148438-10.542969 123.148438s0 83.503906 10.542969 123.148437c5.90625 21.929687 23.195312 39.222656 45.128906 45.128906 40.484375 10.964844 200.328125 10.964844 200.328125 10.964844s160.261719 0 200.328125-10.546875c21.933594-5.902344 39.222656-23.195312 45.128906-45.125 10.542969-40.066406 10.542969-123.148438 10.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0 0" fill="#f00" /><path d="m204.96875 256 133.269531-76.757812-133.269531-76.757813zm0 0" fill="#fff" /></svg>
          </a>

          <a target='_blank' rel="noreferrer" href='https://play.google.com/store/apps/details?id=co.rogers.dctqs' className="svg">
            <img fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701803127/CE/Footer/icons8-play-store-96_ujkum2_w4svtk.png" alt="play store" />
          </a>


        </div>
        <div className="footer-copyright">
          <p>© Copyright {new Date().getFullYear()}. <br/>All Rights Reserved by <a target='_blank' rel="noreferrer" href="https://www.ahsassuredservices.com/web_design_and_development">AHS Assured Services</a></p>
          <div className="footer-copyright-content">
            <Link onClick={up} to={"/privacy-policy"}>Privacy Policy</Link>
          </div>
        </div>

      </footer>
    </div>
  )
}

export default Footer
