import React, { useEffect, useState } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { Link } from 'react-router-dom'
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('Blogs');

const Blog = () => {

  const [blogs, Setblogs] = useState([]);

  useEffect(() => {
    // 👇️ scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      // Update state
      Setblogs(data);
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <div>
      <Header />
      <div className="blog">
        <div className="heading">
          <h1>our blogs</h1>

        </div>
        <div className="blog-body">
          {blogs.map(blog => (
            <div className="blog-card">
              <div className="blog-card-img">
                <img fetchpriority="high" src={blog.Image} alt="blog" />
              </div>
              <div className="blog-card-text">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" /></svg>
                  {blog.Date}
                </span>
                <Link to={"/show/" + blog.id}>{blog.Title}</Link>
              </div>
            </div>
          ))}

        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Blog
