import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyClV4JiakT0G0J75ThPvfudL2NpvvhDx_0",
    authDomain: "competency-enhancers.firebaseapp.com",
    projectId: "competency-enhancers",
    storageBucket: "competency-enhancers.appspot.com",
    messagingSenderId: "831310725808",
    appId: "1:831310725808:web:d8a64b2b82cfd3359d7175",
    measurementId: "G-Q2EGR4QE44"
});

const fb = firebase;

export default fb;